import { createAction, createReducer } from '@reduxjs/toolkit';

interface AuthState {
    token: string | null
}

const setToken = createAction<string>('auth/setToken')

const initialState = { token: null } as AuthState

const authReducer = createReducer(initialState, (builder) => {
    builder.addCase(setToken, (state, action) => {
        state.token = action.payload
    })
})

export {
    authReducer,
    setToken
};