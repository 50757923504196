export const Images = {
  sadIcon: require("../assets/images/sad.png"),
  happyIcon: require("../assets/images/happy.png"),
  neutralIcon: require("../assets/images/neutral.png"),
  leftArrow: require("../assets/images/leftArrow.png"),
  rightArrow: require("../assets/images/rightArrow.png"),
  bigSadIcon: require("../assets/images/bigSadIcon.png"),
  bigNeutralIcon: require("../assets/images/bigNeutralIcon.png"),
  bigHappyIcon: require("../assets/images/bigHappyIcon.png"),
  downArrowIcon: require("../assets/images/downArrow.png"),
  emptyState: require("../assets/images/emptyState.png"),
  monthEmptyState: require("../assets/images/monthEmptyState.png"),
};
