import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Colors, Images } from "../../constants";
import moment from "moment";
import "moment/locale/de";

interface DetailedDiaryItemProps {
  experience: number;
  isSelected?: boolean;
  date: string;
  notes: string;
  title: string;
}

const DetailedDiaryItem = ({
  experience,
  isSelected,
  date,
  notes,
  title,
}: DetailedDiaryItemProps) => {
  const classes = useStyles();
  moment.locale("de");
  const dateString = moment(date).format("dddd, DD.MM.");
  const [showMore, setShowMore] = useState<boolean>(false);

  const getIcon = () => {
    switch (experience) {
      case 3:
        return Images.bigHappyIcon;
      case 2:
        return Images.bigNeutralIcon;
      case 1:
        return Images.bigSadIcon;
      default:
        return Images.bigSadIcon;
    }
  };

  const getReadMore = () => {
    if (notes.length > 50 && !showMore) {
      return (
        <div>
          <p className={classes.details}>{`${notes.slice(0, 50)}...`}</p>
          <div
            className={classes.buttonContainer}
            onClick={() => setShowMore(true)}
          >
            <p className={classes.buttonText}>Weiterlesen</p>
            <img
              src={Images.downArrowIcon}
              className={classes.icon}
              alt={"arrow down icon"}
            />
          </div>
        </div>
      );
    }
    return <p className={classes.details}>{notes}</p>;
  };

  return (
    <div className={classes.container}>
      <img src={getIcon()} alt={"smiley face icon"} />
      {isSelected && <div className={classes.divider} />}
      <div
        className={
          isSelected
            ? classes.selectedContentContainer
            : classes.contentContainer
        }
      >
        <p className={isSelected ? classes.selectedDate : classes.date}>
          {dateString}
        </p>
        <p className={classes.title}>{title}</p>
        {getReadMore()}
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "flex-start",
    borderWidth: "0px",
    borderBottomWidth: "2px",
    borderStyle: "solid",
    borderColor: Colors.coldBlue,
    paddingBottom: "20px",
    paddingTop: "10px",
    marginBottom: "10px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "18px",
  },
  selectedContentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  date: {
    fontWeight: "700",
    fontSize: "13px",
    margin: "0px",
    color: Colors.petrolGray,
  },
  selectedDate: {
    fontWeight: "700",
    fontSize: "13px",
    margin: "0px",
    color: Colors.darkPink,
  },
  title: {
    fontWeight: "700",
    fontSize: "16px",
    color: Colors.darkBlue,
    marginTop: "2px",
    marginBottom: "10px",
  },
  details: {
    fontWeight: "400",
    fontSize: "14px",
    margin: "0px",
    color: Colors.petrolGray,
  },
  divider: {
    alignSelf: "stretch",
    width: "0px",
    borderRadius: "2px",
    marginLeft: "7px",
    marginRight: "7px",
    borderWidth: "2px",
    borderColor: Colors.darkPink,
    border: "solid",
    backgroundColor: Colors.darkPink,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  icon: {
    alignSelf: "center",
    marginLeft: "5px",
    marginTop: "3px",
  },
  buttonText: {
    color: Colors.darkPink,
    fontSize: "14px",
    fontWeight: "700",
  },
});

export default DetailedDiaryItem;
