import React from "react";
import { createUseStyles } from "react-jss";
import { Colors, Images } from "../../constants";

interface GlobalEmptyStateProps {
  firstName: string;
  lastName: string;
}

const GlobalEmptyState = ({ firstName, lastName }: GlobalEmptyStateProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h1 className={classes.headline}>Keine Einträge vorhanden</h1>
      <div>
        <img src={Images.emptyState} />
      </div>
      <p className={classes.description}>
        {`${firstName} ${lastName} `}
        hat noch keine <br />
        Einträge erstellt, oder teilt Einträge <br />
        derzeit noch nicht mit dem Fachgeschäft.
      </p>
    </div>
  );
};

const useStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "844px",
  },
  headline: {
    fontSize: "24px",
    lineHeight: "30px",
    color: Colors.darkBlue,
  },
  description: {
    fontSize: "16px",
    lineHeight: "20px",
    color: Colors.darkBlue,
    textAlign: "center",
    fontFamily: "",
  },
});

export default GlobalEmptyState;
