export const Colors = {
    darkBlue: "#00265D",
    coldBlue: "#CAD4DF",
    warmBlue: "#809CB9",
    lightBlue: "#F0F3F7",
    warningRed: "#D61414",
    interactionGray: "#A8A8A8",
    inactiveGray: "#f1f1f1",
    buttonBlue: "#5D90D5",
    petrolGray: "#414C5D",
    darkPink: '#960045',
    feedbackGreen: '#00B919',
    white: "#FFFFFF",
}
