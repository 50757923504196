import React, { RefObject } from "react";
import { useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { Colors, Images } from "../../constants";
import ActivityItem from "../../components/ActivityItem/ActivityItem";
import {
  useGetDetailedDiariesQuery,
  useGetDiariesByUsersIdQuery,
  useGetEntriesStateQuery,
  useGetMostUsedEmotionsQuery,
  useGetMostUsedSituationsQuery,
  useGetWorstValuesQuery,
} from "../../api/phoenix";
import IconActivityItem from "../../components/IconActivityItem/IconActivityItem";
import DatePicker from "../../components/DatePicker/DatePicker";
import { useState } from "react";
import moment from "moment";
import DetailedDiaryItem from "../../components/DetailedDiaryItem/DetailedDiaryItem";
import { Diary } from "../../types/diaryTypes";
import MostUsedSituationsWidget from "../../components/MostUsedSituationsWidget/MostUsedSituationsWidget";
import EmptyState from "../../components/EmptyState/EmptyState";
import MostUsedEmotionsWidget from "../../components/MostUsedEmotionsWidget/MostUsedEmotionsWidget";
import WorstValuesWidget from "../../components/WorstValuesWidget/WorstValuesWidget";
import NewEntriesWidget from "../../components/NewEntriesWidget/NewEntriesWidget";
import GlobalEmptyState from "../../components/GlobalEmptyState/GlobalEmptyState";

export default function Detail() {
  const { id } = useParams();
  const [month, setMonth] = useState<number>(moment().month() + 1);
  const [year, setYear] = useState<number>(moment().year());
  const {
    data: diaryEntriesState,
    isLoading: diaryEntriesStateLoading,
  } = useGetEntriesStateQuery({ id: id || "" }, { skip: !id });
  const {
    data: diariesData,
    isLoading: isDiaryLoading,
  } = useGetDiariesByUsersIdQuery(
    {
      id: id || "",
      month,
      year,
    },
    { skip: !id && !!diaryEntriesState?.data.diaryEntries }
  );
  const {
    data: detailedDiariesData,
    isLoading: isDetailedDiaryLoading,
  } = useGetDetailedDiariesQuery(
    {
      id: id || "",
      month,
      year,
    },
    { skip: !id && !!diaryEntriesState?.data.diaryEntries }
  );
  const {
    data: mostUsedSituations,
    isLoading: isMostUsedLoading,
  } = useGetMostUsedSituationsQuery(
    { id: id || "", month, year },
    { skip: !id && !!diaryEntriesState?.data.diaryEntries }
  );
  const {
    data: mostUsedEmotions,
    isLoading: isMostUsedEmotionsLoading,
  } = useGetMostUsedEmotionsQuery(
    { id: id || "", month, year },
    { skip: !id && !!diaryEntriesState?.data.diaryEntries }
  );

  const {
    data: worstValues,
    isLoading: isWorstValuesLoading,
  } = useGetWorstValuesQuery(
    { id: id || "", month, year },
    { skip: !id && !!diaryEntriesState?.data.diaryEntries }
  );

  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const isLoading =
    isDiaryLoading ||
    isDetailedDiaryLoading ||
    isMostUsedLoading ||
    isMostUsedEmotionsLoading ||
    isWorstValuesLoading ||
    diaryEntriesStateLoading;
  const classes = useStyles();

  const getVariant = (value: number | undefined) => {
    switch (value) {
      case 0:
        return "low";
      case 1:
        return "low";
      case 2:
        return "mid";
      case 3:
        return "high";
      default:
        return undefined;
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const onDayPress = (isSelected: boolean, day: string) => {
    if (isSelected) {
      setSelectedDay(null);
      return;
    }
    setSelectedDay(day);
  };

  const refs =
    detailedDiariesData &&
    detailedDiariesData.data.diaries.reduce((acc, value) => {
      // @ts-ignore
      acc[value._id] = React.createRef();
      return acc;
    }, {});

  const onItemPress = (item: Diary | null, isSelected: boolean) => {
    if (item) {
      //@ts-ignore
      refs[item._id].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      onDayPress(isSelected, moment(item.day).format("D"));
    }
  };

  return !diaryEntriesState?.data.diaryEntries ? (
    <GlobalEmptyState
      firstName={diaryEntriesState?.data.firstName || ""}
      lastName={diaryEntriesState?.data.lastName || ""}
    />
  ) : (
    <main className={classes.main}>
      {/* ADD THEM BACK AFTER GO LIVE*/}
      {/*<div className={classes.widgetsContainer}>*/}
      {/*  {detailedDiariesData && (*/}
      {/*    <NewEntriesWidget*/}
      {/*      newEntriesNo={detailedDiariesData.data.diaries.length}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*  {mostUsedSituations && (*/}
      {/*    <div className={classes.widgetContainer}>*/}
      {/*      <MostUsedSituationsWidget*/}
      {/*        situations={mostUsedSituations?.data?.situations}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*  {mostUsedEmotions && (*/}
      {/*    <div className={classes.widgetContainer}>*/}
      {/*      <MostUsedEmotionsWidget*/}
      {/*        emotions={mostUsedEmotions.data.experiences}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*  {worstValues && (*/}
      {/*    <div className={classes.widgetContainer}>*/}
      {/*      <WorstValuesWidget values={worstValues.data.values} />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
      <div className={classes.pickerContainer}>
        <DatePicker
          getDate={(date) => {
            setMonth(date.month() + 1);
            setYear(date.year());
          }}
        />
        <div className={classes.divider} />
      </div>
      {detailedDiariesData && !detailedDiariesData.data.diaries.length ? (
        <EmptyState
          firstName={detailedDiariesData?.data.firstName}
          lastName={detailedDiariesData?.data.lastName}
        />
      ) : (
        <div className={classes.row}>
          <div className={classes.rowContainer}>
            <div className={classes.titlesContainer}>
              <div className={classes.experienceHeaderItem}>
                <h3 className={classes.experienceTitle}>{"Hörempfinden"}</h3>
              </div>
              <div className={classes.headerItem}>
                <h3 className={classes.activeText}>{"Klang"}</h3>
                <h3 className={classes.subtitle}>{"Natürlich"}</h3>
                <h3 className={classes.subtitle}>{"Künstlich"}</h3>
              </div>
              <div className={classes.headerItem}>
                <h3 className={classes.activeText}>{"Sprachverstehen"}</h3>
                <h3 className={classes.subtitle}>{"Deutlich"}</h3>
                <h3 className={classes.subtitle}>{"Undeutlich"}</h3>
              </div>
              <div className={classes.headerItem}>
                <h3 className={classes.activeText}>{"Laustärke"}</h3>
                <h3 className={classes.subtitle}>{"Angenehm"}</h3>
                <h3 className={classes.subtitle}>{"Unangenehm"}</h3>
              </div>
            </div>
            {diariesData && (
              <div className={classes.scrollView}>
                {diariesData.data.diaries.map((item, index) => {
                  const isSelected =
                    item.length !== 0 &&
                    selectedDay === moment(item[0].day).format("D");
                  return (
                    <div
                      className={
                        isSelected ? classes.selected : classes.default
                      }
                      onClick={() => {
                        onItemPress(item[0], isSelected);
                      }}
                      key={index.toString()}
                    >
                      {item.length ? (
                        <div className={classes.column}>
                          <p className={classes.activeText}>{index + 1}</p>
                          <div className={classes.row}>
                            {item.map((diary, idx) => {
                              return (
                                <div
                                  className={classes.column}
                                  key={idx.toString()}
                                >
                                  <IconActivityItem
                                    variant={getVariant(diary?.experience)}
                                  />
                                  <ActivityItem
                                    variant={getVariant(
                                      diary?.sound || undefined
                                    )}
                                  />
                                  <ActivityItem
                                    variant={getVariant(
                                      diary?.speechComprehension || undefined
                                    )}
                                  />
                                  <ActivityItem
                                    variant={getVariant(
                                      diary?.overallVolume || undefined
                                    )}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <div className={classes.inactiveContainer}>
                          <p className={classes.inactiveText}>{index + 1}</p>
                          <div>
                            <IconActivityItem variant={getVariant(undefined)} />
                            <ActivityItem variant={getVariant(undefined)} />
                            <ActivityItem variant={getVariant(undefined)} />
                            <ActivityItem variant={getVariant(undefined)} />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className={classes.detailsContent}>
            {detailedDiariesData && (
              <ul style={{ width: "100%" }}>
                {detailedDiariesData.data.diaries.map((item, index) => (
                  <li
                    key={item._id}
                    //@ts-ignore
                    ref={refs[item._id]}
                    className={classes.noBullet}
                  >
                    <DetailedDiaryItem
                      isSelected={selectedDay === moment(item.day).format("D")}
                      title={item.situation}
                      notes={item.notes}
                      date={item.day}
                      experience={item.experience}
                      key={index.toString()}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </main>
  );
}

const useStyles = createUseStyles({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    width: "70%",
  },
  experienceHeaderItem: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
    height: "88px",
    width: "130px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  experienceTitle: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    color: Colors.darkBlue,
    textAlign: "center",
    marginTop: "45px",
  },
  titlesContainer: {
    borderWidth: "1px",
    borderLeftWidth: "0px",
    borderRightWidth: "0px",
    border: "solid",
    borderColor: Colors.coldBlue,
  },
  headerItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "125px",
    width: "130px",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderWidth: "0px",
    borderTopWidth: "1px",
    borderColor: Colors.coldBlue,
    border: "solid",
  },
  activeText: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    color: Colors.darkBlue,
    textAlign: "center",
  },
  inactiveText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: Colors.interactionGray,
    textAlign: "center",
  },
  subtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: Colors.darkBlue,
    textAlign: "center",
  },
  selected: {
    display: "flex",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: Colors.darkPink,
  },
  default: {
    display: "flex",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: Colors.coldBlue,
  },
  detailsContent: {
    display: "flex",
    height: "464px",
    marginLeft: "21px",
    overflowY: "scroll",
    width: "30%",
  },
  pickerContainer: {
    width: "100%",
    borderWidth: "0px",
    borderBottomWidth: "1px",
    paddingTop: "16px",
    paddingBottom: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: Colors.white,
    marginTop: "0px", // Change it to 14px after go live
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    flexDirection: "column",
  },
  inactiveContainer: {
    display: "flex",
    flexDirection: "column",
    background: `${Colors.inactiveGray}59`,
  },
  column: { display: "flex", flexDirection: "column" },
  columnText: { flexDirection: "column", overflowX: "scroll", width: "44px" },
  row: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
  },
  scrollView: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    overflowX: "scroll",
  },
  main: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "58px",
    paddingBottom: "30px",
  },
  divider: {
    height: "1px",
    width: "100%",
    alignSelf: "center",
    backgroundColor: Colors.coldBlue,
    marginTop: "15px",
    marginBottom: "25px",
  },
  widgetContainer: {
    marginLeft: "10px",
  },
  widgetsContainer: { display: "flex", flexDirection: "row" },
  noBullet: {
    width: "100%",
    listStyleType: "none",
  },
});
