import React from "react";
import { createUseStyles } from "react-jss";
import { Colors, Images } from "../../constants";

interface IconActivityItemProps {
  variant?: "low" | "mid" | "high";
}

const IconActivityItem = ({ variant }: IconActivityItemProps) => {
  const classes = useStyles();

  const getIcon = () => {
    switch (variant) {
      case "high":
        return Images.happyIcon;
      case "mid":
        return Images.neutralIcon;
      case "low":
        return Images.sadIcon;
      default:
        return Images.sadIcon;
    }
  };

  return variant ? (
    <div className={classes.dayItem}>
      <img className={classes.icon} src={getIcon()} alt={"smiley face icon"} />
    </div>
  ) : (
    <div className={classes.inactiveDayItem} />
  );
};

const useStyles = createUseStyles({
  dayItem: {
    width: "22px",
    height: "42px",
  },
  inactiveDayItem: {
    width: "22px",
    height: "42px",
  },
  icon: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

export default IconActivityItem;
