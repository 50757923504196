import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppDispatch, RootState } from "..";
import { setToken } from "../reducers/authReducer";
import { DetailedDiary, Diary } from "../types/diaryTypes";

type UserListItem = {
  _id: string;
  firstName: string;
  lastName: string;
};

type UserListRecord = UserListItem[];

type UserListLetter = {
  data: UserListRecord[];
  title: string;
};

type UserListResultInner = {
  usersList: UserListLetter[];
};

type ApiResult<T> = {
  data: T;
};

const {
  REACT_APP_BACKEND_URL: BACKEND_URL,
  REACT_APP_BACKEND_FEATURE_BRANCH: BACKEND_FEATURE_BRANCH,
  REACT_APP_BACKEND_API_MGMT_KEY: BACKEND_API_MGMT_KEY,
} = process.env;

const phoenixApi = createApi({
  reducerPath: "phoenixApi",
  baseQuery: fetchBaseQuery({
    //baseUrl: "http://localhost:3005/kindapp",
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      let token = (getState() as RootState).auth.token;
      if (!token) {
        const auth = localStorage.getItem("phoenixAuthentication");
        if (auth) {
          const { authenticationToken } = JSON.parse(auth);
          token = authenticationToken;
        }
      }

      if (token) {
        if (BACKEND_FEATURE_BRANCH) {
          headers.set("feature-branch", `${BACKEND_FEATURE_BRANCH}`);
        }

        headers.set("Ocp-Apim-Subscription-Key", `${BACKEND_API_MGMT_KEY}`);
        headers.set("Authorization", `Bearer ${token}`);
      } else {
        throw new Error("No Authentication given");
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAuthenticate: builder.query<ApiResult<boolean>, number>({
      query: () => `/phoenixAuthenticate`,
    }),
    getUsersByLetter: builder.query<ApiResult<UserListResultInner>, number>({
      query: () => `/phoenixUsersList`,
    }),
    getDiariesByUsersId: builder.query<
      ApiResult<{ diaries: Array<Diary[]> }>,
      { id: string; month: number; year: number }
    >({
      query: ({ id, month, year }) =>
        `/phoenixUserDiaries?userId=${id}&month=${month}&year=${year}`,
    }),
    getDetailedDiaries: builder.query<
      ApiResult<{
        firstName: string;
        lastName: string;
        diaries: DetailedDiary[];
      }>,
      { id: string; month: number; year: number }
    >({
      query: ({ id, month, year }) =>
        `/phoenixDetailedUserDiaries?userId=${id}&month=${month}&year=${year}`,
    }),
    getMostUsedSituations: builder.query<
      ApiResult<{ situations: Array<{ _id: "string"; count: number }> }>,
      { id: string; month: number; year: number }
    >({
      query: ({ id, month, year }) =>
        `/phoenixMostUsedSituations?userId=${id}&month=${month}&year=${year}`,
    }),
    getMostUsedEmotions: builder.query<
      ApiResult<{ experiences: Array<{ _id: "string"; count: number }> }>,
      { id: string; month: number; year: number }
    >({
      query: ({ id, month, year }) =>
        `/phoenixMostUsedEmotions?userId=${id}&month=${month}&year=${year}`,
    }),
    getWorstValues: builder.query<
      ApiResult<{ values: Array<{ category: "string"; count: number }> }>,
      { id: string; month: number; year: number }
    >({
      query: ({ id, month, year }) =>
        `/phoenixWorstValues?userId=${id}&month=${month}&year=${year}`,
    }),
    getEntriesState: builder.query<
      ApiResult<{ diaryEntries: number; firstName: string; lastName: string }>,
      { id: string }
    >({
      query: ({ id }) => `/phoenixDiaryEntriesState?userId=${id}`,
    }),
  }),
});

/**
 * Notifies Phoenix 2 that this site is ready to receive
 * the access token of the employee and to load the diary of a user.
 */
export const notifyPhoenixWindowReady = () => {
  const readyMessage = {
    method: "POST",
    url: "/ready",
  };
  window.parent.postMessage(readyMessage, "*");
};

/**
 * Notifies Phoenix 2 that the diary of a user was loaded.
 */
export const notifyPhoenixWindowLoaded = () => {
  const readyMessage = {
    method: "POST",
    url: "/loaded",
  };
  window.parent.postMessage(readyMessage, "*");
};

/**
 * Notifies Phoenix 2 that an error has occured when loading the diary of a user.
 * @param code
 * @param message
 */
export const notifyPhoenixWindowError = (code: string, message: string) => {
  const readyMessage = {
    method: "POST",
    url: "/error",
    body: {
      code: code,
      message: message,
    },
  };
  window.parent.postMessage(readyMessage, "*");
};

/**
 * Creates a listener that can handle messages sent by the Phoenix 2 window.
 * @param dispatch
 * @returns a callback function that can be attached as an event listener
 */
export const createPhoenixMessageListener = (dispatch: AppDispatch) => {
  return (event: MessageEvent) => {
    if (!event.data) {
      return;
    }

    if (event.data.method === "POST" && event.data.url === "/load") {
      const { accessToken } = event.data.body;
      dispatch(setToken(accessToken));
    }
  };
};

export const {
  reducerPath,
  reducer,
  middleware,
  useGetAuthenticateQuery,
  useGetUsersByLetterQuery,
  useGetDiariesByUsersIdQuery,
  useGetDetailedDiariesQuery,
  useGetMostUsedSituationsQuery,
  useGetMostUsedEmotionsQuery,
  useGetWorstValuesQuery,
  useGetEntriesStateQuery,
} = phoenixApi;
