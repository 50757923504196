import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Colors, Images } from '../../constants';
import moment, { Moment } from 'moment';
import 'moment/locale/de';

interface DatePickerProps {
  getDate: (date: Moment) => void;
}

const DatePicker = ({ getDate }: DatePickerProps) => {
  moment.locale('de');
  const [date, setDate] = useState<Moment>(moment());
  const startOfMonth = useMemo(() => date.startOf('month').format('DD'), [date]);
  const endOfMonth = useMemo(() => date.endOf('month').format('DD'), [date]);

  const increment = () => {
    const newDate = date.clone().add(1, 'M');
    getDate(newDate);
    setDate(newDate);
  };

  const decrement = () => {
    const newDate = date.clone().subtract(1, 'M');
    getDate(newDate);
    setDate(newDate);
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.arrowButton} onClick={decrement}>
        <img src={Images.leftArrow} alt={'left arrow'} />
      </div>
      <p className={classes.title}>{`${startOfMonth}. - ${endOfMonth}. ${date.format('MMMM YYYY')}`}</p>
      <div className={classes.arrowButton} onClick={increment}>
        <img src={Images.rightArrow} alt={'right arrow'} />
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '42px',
    width: '42px',
    borderRadius: '21px',
    backgroundColor: Colors.buttonBlue,
  },
  title: {
    marginLeft: '10px',
    marginRight: '10px',
    color: Colors.petrolGray,
    fontSize: '18px',
    width: '220px',
    textAlign: 'center',
  },
});

export default DatePicker;
