import React from 'react';
import { createUseStyles } from 'react-jss';
import { Colors } from '../../constants';

interface ActivityItemProps {
  variant?: 'low' | 'mid' | 'high';
}

const ActivityItem = ({ variant }: ActivityItemProps) => {
  const classes = useStyles();

  const renderItem = () => {
    switch (variant) {
      case 'high':
        return <div className={classes.highDot} />;
      case 'mid':
        return <div className={classes.midDot} />;
      case 'low':
        return (
          <div className={classes.lowDotBackground}>
            <div className={classes.lowDot} />
          </div>
        );
      default:
        return <div className={classes.inactive}></div>;
    }
  };

  return <div className={classes.activityItem}>{renderItem()}</div>;
};

const useStyles = createUseStyles({
  activityItem: {
    display: 'flex',
    flexDirection: 'column',
    height: '125px',
    width: '22px',
    borderWidth: '0px',
    borderTopWidth: '1px',
    borderColor: Colors.coldBlue,
    border: 'solid',
  },
  highDot: {
    height: '10px',
    width: '10px',
    borderRadius: '5px',
    backgroundColor: Colors.feedbackGreen,
    alignSelf: 'center',
    marginTop: '250%',
  },
  midDot: {
    height: '10px',
    width: '10px',
    borderRadius: '5px',
    backgroundColor: Colors.warmBlue,
    alignSelf: 'center',
    marginTop: '350%',
  },
  lowDot: {
    height: '10px',
    width: '10px',
    borderRadius: '5px',
    backgroundColor: Colors.warningRed,
    alignSelf: 'center',
    marginTop: '430%',
  },
  lowDotBackground: {
    display: 'flex',
    backgroundColor: `${Colors.warningRed}1A`,
    justifyContent: 'center',
    height: '100%',
  },
  inactive: {
    height: '100%',
    backgroundColor: `${Colors.inactiveGray}59`,
  },
});

export default ActivityItem;
