import './App.css';
import {
  Routes,
  Route,
  Link,
  HashRouter
} from "react-router-dom";
import Home from './routes/home';
import Detail from './routes/detail';
import { createUseStyles } from 'react-jss'
import { useEffect, useState } from 'react';
import { createPhoenixMessageListener, notifyPhoenixWindowReady, useGetAuthenticateQuery } from "./api/phoenix";
import { RootState, useAppDispatch } from '.';
import { useSelector } from 'react-redux';
import { setToken } from './reducers/authReducer';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const useStyles = createUseStyles({
  myButton: {
    color: 'green',
    margin: {
      // jss-expand gives more readable syntax
      top: 5, // jss-default-unit makes this 5px
      right: 0,
      bottom: 0,
      left: '1rem'
    },
    '& span': {
      // jss-nested applies this to a child span
      fontWeight: 'bold' // jss-camel-case turns this into 'font-weight'
    }
  },
  myLabel: {
    fontStyle: 'italic'
  }
})

function AuthenticationOverlay() {
  const [formData, setFormData] = useState({
    authenticationToken: "",
  })

  useEffect(() => {
    if (formData.authenticationToken) {
      localStorage.setItem("phoenixAuthentication", JSON.stringify(formData));
      window.location.reload()
    }
  }, [formData, formData.authenticationToken]);

  return (
    <form onSubmit={(ev) => {
      ev.preventDefault()
    }}>
      Please enter credentials:
      <div>
        <label htmlFor="authenticationToken">Authentication Token:</label>
        <textarea onChange={(e) => setFormData({ ...formData, authenticationToken: e.target.value })} name="authenticationToken" />
      </div>
      <button type="submit">Submit</button>
    </form>
  )
}


function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const listener = createPhoenixMessageListener(dispatch);
    window.addEventListener('message',  listener);
    notifyPhoenixWindowReady();
    return () => {
      window.removeEventListener('message',  listener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    
  const classes = useStyles();
  const hasToken = useSelector<RootState, boolean>(state => !!state.auth.token);
  const { error, isLoading } = useGetAuthenticateQuery(0, {
    skip: !hasToken
  });

  if (!hasToken || isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Fehler bei der Anmeldung: {JSON.stringify(error)}</div>
  }

  return (
    <HashRouter>
      <div>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="detail/:id" element={<Detail />} />
        </Routes>
      </div>
    </HashRouter>

  );
}

export default App;
